<template>
  <Overview
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="title"
        :selectFields=selectFields
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="title" :header="$t('overview.title')" :sortable="true">
              <template #body="slotProps">
                {{slotProps.data.title ? slotProps.data.title : 'untitled'}}
              </template>
            </Column>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import kbService from '@/services/KnowledgeBaseService'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overview.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: kbService,
            itemFormRef: null,
            navName: 'navigation.knowledge_base',
            companyTypes: [],
            selectFields: []
        }
    }
}
</script>
